* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  background-color: #f0eeef;
}

body {
  font-family: "Cairo", serif;
}

.link {
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
}

.field-error {
  color: red;
  font-size: 12px;
  margin: 0;
}

.contact-btn {
  cursor: pointer;
  display: flex;
  width: 15rem;
  max-width: 100%;
  height: 2rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  background: #a2c138;
  color: #fff;
  text-align: center;
  font-family: Cairo, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.33rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  z-index: 11;
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

/* Hover Effect */
.contact-btn:hover {
  background: #8eb331;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-btn {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    width: 10rem;
    max-width: 100%;
    height: 2rem;
  }
}

@media (max-width: 460px) {
  .contact-btn {
    font-size: 0.7rem;
    padding: 0.3rem 1rem;
    width: 6.5rem;
    max-width: 100%;
    height: 1rem;
  }
}
